@import "../variables.scss";

.customBG {
  background-image: url('../assets/background.jpg');
  background-color: #2D2636;
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: Avenir, Arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* stylelint-disable-next-line at-rule-no-unknown */
  @extend .customBG;

  /* TODO remove this and resolve the undefined issue on routing with cache disabled and slow network */
  #app > div:not([class]) {
    color: transparent;
  }
}

button {
  font-family: Avenir, Arial, sans-serif;
}

* {
  box-sizing: border-box;
  outline: none;
}

/* stylelint-disable-next-line selector-max-id */
#app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

h1 {
  font-size: $font-size-xl;
  font-weight: 600;
}

h2 {
  font-size: $font-size-lg;
  font-weight: 600;
  margin: 0;
  line-height: 32px;
}

h3 {
  font-size: $font-size-md;
  font-weight: 600;
}

h4 {
  font-size: $font-size-sm;
  font-weight: 600;
  margin: 0;
  line-height: 25px;
}

h5 {
  font-size: $font-size-xs;
  font-weight: 400;
  margin: 0;
}

h6 {
  font-size: $font-size-xl;
  font-weight: 700;
}

p {
  font-size: $font-size-sm;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

a {
  color: $black;
}

input,
textarea {
  font-family: Avenir, Arial, sans-serif;
  border: 2px solid rgb(214,215,216);
  display: block;
  font-size: 18px;
  width: 100%;
  font-weight: 400;
  color: $grey-dark;
  margin: 5px 0 0;
  border-radius: 6pt;
  padding: 8px;

  &:focus {
    border: 2px solid;
    border-color: inherit;
  }

  &:not([type="checkbox"], [type="radio"]) {
    -webkit-appearance: none;
    box-shadow: none !important;
  }
}

.textContainer {
  color: $main-text-color;

  &.inverted {
    color: white;
  }

  &.capitalize {
    text-transform: uppercase;
  }

  &.center {
    text-align: center;
  }
}
