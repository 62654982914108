$modal-z: 80;

@import "../../../variables.scss";
@import "bulma/sass/utilities/_all";
@import "bulma/sass/components/modal";

.modal {
  composes: modal;
  text-align: center;
  justify-content: flex-end;
}

.active {
  composes: is-active;
  color: $main-text-color;
}

.background {
  composes: modal-background;
  background-color: rgba(30, 30, 36, .8);
}

.modalCard {
  composes: modal-card;
  margin: $content-padding;
  width: calc(100% - (#{$content-padding} * 2));
  border-radius: 20pt;
  background: url('../../../assets/background.jpg');
  border-color: white;
  overflow: visible;
  max-height: calc(100% - 20px) !important;
}

.header {
  composes: modal-card-head;
  background-color: $background-color;
  border-bottom: 0;
  border-color: white;
  border-top-left-radius: 20pt;
  border-top-right-radius: 20pt;
  padding: $spacing-xxs;

  &> h2 {
    width: 100%;
  }
}

.headerShadow {
  composes: header;
  box-shadow: $box-shadow-bottom;
}

.close {
  position: absolute;
  right: 0;
  top: -30px;
  color: $white;
  display: flex;
  align-items: center;
  font-size: $font-size-sm;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  font-family: Avenir, Arial, sans-serif;
  background: none;
  border: 0;
}

.closeButton {
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: 10px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 50%;
    left: 0;
    background: $white;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.body {
  composes: modal-card-body;
  padding: 0 18px;
  background-color: $background-color;
  scroll-behavior: smooth;

  button:not(:last-child) {
    margin-bottom: 10px;
  }
}

.footer {
  composes: modal-card-foot;
  border-top: 0;
  background-color: $background-color;
  padding: 0 18px 20px;
  text-align: center;
  display: block;
  overflow: hidden;
  border-bottom-left-radius: 20pt;
  border-bottom-right-radius: 20pt;
  color: $main-text-color;
}
